<!--
 * @Description: 客户详情
 * @Author: xiawenlong
 * @Date: 2021-05-19 11:03:23
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-05-20 09:48:04
-->
<template>
  <div class="customer-info">
    <div class="title">客户信息</div>
    <div class="info-card">
      <div class="avatar">
        <img :src="info.headPic" alt="" />
      </div>
      <div class="text">
        <p>
          <span>客户名称：{{ info.consumerName }}</span
          ><span>客户性别：{{ info.gender }}</span>
        </p>
        <p>
          <span>手机号码：{{ info.consumerPhone }}</span>
          <span>注册时间：{{ info.registrationTime }}</span>
        </p>
      </div>
    </div>
    <table-list
      title="统计数据"
      :loading="loading"
      :data="detailList"
      :columns="columns(this)"
      :pager="pager"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>
<script>
// 表格列配置
const columns = () => [
  {
    prop: 'productName',
    label: '商品名称',
  },
  {
    prop: 'productPrice',
    label: '单价(元)',
  },
  {
    prop: 'purchaseTime',
    label: '购买时间',
  },
]
import TableList from '@/components/TableList'
import { getConsumerDetailList, getConsumerInfo } from '@/api/course'
import to from 'await-to'
export default {
  name: 'CustomerDetail',
  components: {
    TableList,
  },
  data() {
    return {
      columns,
      loading: false,
      userId: '',
      info: {},
      detailList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
    }
  },
  mounted() {
    const { userId } = this.$route.params
    this.userId = userId
    this.getDetailInfo()
    this.getDetailList()
  },
  methods: {
    async getDetailList() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(getConsumerDetailList({ current, size, userId: this.userId }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.detailList = res.data.list
      this.pager.total = res.data.total
    },
    async getDetailInfo() {
      const [res, err] = await to(getConsumerInfo({ userId: this.userId }))
      if (err) return this.$message.warning(err.msg)
      this.info = res.data
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getDetailList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getDetailList()
    },
  },
}
</script>
<style lang="scss" scoped>
.customer-info {
  .title {
    font-size: 18px;
    font-weight: 500;
    color: #4d4e5d;
    margin-bottom: 20px;
  }
  .info-card {
    width: 100%;
    height: 80px;
    background: #ffffff;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    .avatar {
      width: 60px;
      height: 60px;
      margin-right: 20px;
      img {
        @extend %width-height;
      }
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      span {
        width: 200px;
        display: inline-block;
      }
      &:nth-of-type(1) {
        margin-bottom: 12px;
      }
    }
  }
}
</style>
